import React from "react";
import { Helmet } from 'react-helmet'
import "./App.scss"
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { LandingForm } from "../../components/landingForm"
import imgLogo from "./assets/img/logo-ucc.webp";
import imgBanner from "./assets/img/hero/agromanagement.webp";
import imgCalendar from "./assets/img/icons/calendar.webp";
import imgPin from "./assets/img/icons/pin.webp";
import imgReloj from "./assets/img/icons/reloj.webp";
import imgDinero from "./assets/img/icons/dinero.webp";

const Agromanagement = () => {
	return (
		<>
			<Helmet>
				<script>
				{`(function(w,d,s,l,i)
					{w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});
					var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
					j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','GTM-PLKH83F');`}
				</script>

				<noscript>
				{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PLKH83F"
						height="0" width="0" style="display:none;visibility:hidden"> 
					</iframe>`}
				</noscript>
			</Helmet>
			<Navbar sticky="top" bg="azul" className="navbar-landing">
				<Container
					style={{
						justifyContent: "start",
					}}
				>
					<Navbar.Brand>
						<img
							src={imgLogo}
							width="100%"
							height="100%"
							alt="Diplomatura en Agromanagement"
						/>
					</Navbar.Brand>
				</Container>
			</Navbar>
			<main>
				<section
					className="hero hero-azul d-flex align-items-center"
					style={{ backgroundImage: `url(${imgBanner})` }}
				>
					<Container className="position-relative">
						<Row>
							<Col className="gap-2 d-flex flex-column">
								<div className="d-flex align-items-center gap-2 gap-md-3">
									<h3
										className="bg-white text-azul fw-light m-0"
										style={{ padding: '0.8rem' }}
									>
										Diplomatura{" "}
										<span className="fw-extrabold">
											Online
										</span>
									</h3>{" "}
									<h4 className="m-0 fw-light text-uppercase text-white">
										Inicio{" "}
										<span className="d-block fw-extrabold">
											14 de abril
										</span>
									</h4>
								</div>
								<h1>Diplomatura en Agromanagement</h1>
								<h5 className="text-white text-uppercase fw-semibold">
									Primera Universidad Privada de Argentina
								</h5>
								<a
									href="#formulario"
									className="btn btn-hero btn-hero-azul shadow-sm d-flex align-self-center align-self-md-start smooth"
								>
									Inscríbete ahora
								</a>
							</Col>
						</Row>
					</Container>
				</section>

				<section id="descripcion" className="bg-light-landing">
                <Container>
                        <Row>
                            <Col md={6} className="pt-5 pb-3 py-md-6">
								<h3 className="title text-azul">
									Consúltanos{" "}
									<span className="d-block">
										por este curso
									</span>
								</h3>
								<p className="p-style">
                                    El Diplomado en Agromanagement responde a la necesidad del sector agropecuario, agroalimentario y agroindustrial, de desarrollar capacidades gerenciales estratégicas en sus organizaciones, adecuadas a un contexto desafiante y en constante evolución.
								</p>
								<p className="p-style">
                                    Las tendencias del mercado agroalimentario global, sumado a los cambios estructurales que se expresan en lo económico, tecnológico, demográfico y medioambiental, constituyen un enorme desafío para las empresas del medio. La visión estratégica y la incorporación de modernas herramientas de Management, resultan factores claves de éxito.
								</p>
								<p className="p-style">
                                    La Facultad de Ciencias Agropecuarias - Universidad Católica de Córdoba, resulta un ámbito propicio para el desarrollo del presente programa, por contar con una extensa trayectoria en la formación integral de profesionales del sector, tanto a través de su oferta calificada de carreras de grado, como en particular, desde de su Maestría en Agronegocios y Alimentos que imparte desde el año 2007.
								</p>
								<p className="p-style">
                                    Diseñado y organizado por la Facultad de Ciencias Agropecuarias.
								</p>
								<p className="p-style">
                                    Se otorgará certificación digital oficial de la Universidad Católica de Córdoba, con opción para adquirir la certificación en papel.
								</p>

								<h5 className="p-style fw-bold">Docentes:</h5>
								<ul>
                                    <li>Mag. Giletta, Martin Alfredo (Director - Disertante)</li>
                                    <li>Cr. Scarpetta, Gustavo Martin (Disertante)</li>
                                    <li>Dr. Bongiovanni, Rodolfo Gustavo (Disertante)</li>
                                    <li>Mag. Garzon, Juan Manuel (Disertante)</li>
                                    <li>Mag. Giletta, Martin Alfredo (Disertante)</li>
                                    <li>Mas. Hermida, RaúL Carlos Hector (Disertante)</li>
                                    <li>Mgter. García Espeche, Gonzalo Javier (Disertante)</li>
								</ul>
                                </Col>
                            
                            <Col md={6} className="pt-5 pb-6 py-md-6 px-md-4" id="formulario">
								<div className='container px-4'>
									<LandingForm nomCurso="Agromanagement"/>
								</div>                            
                            </Col>
                        </Row>
					</Container>
				</section>
				<section className="position-relative">
					<a href="#resume" className="smooth icon-down">
						<FontAwesomeIcon icon={faArrowDown} style={{ fontSize: 40, width: 100 }} className="bi bi-arrow-down-short d-flex justify-content-center pt-2 text-azul"/>						
					</a>
					<Container>
						<Row id="resume" className="justify-content-center">
							<Col
								sm={11}
								className="bg-white rounded-4 shadow-sm mt-n4 mb-5"
								style={{ zIndex: 1 }}
							>
								<Row className="row-cols-2 row-cols-md-4 py-2 py-md-0">
									<Col className="my-auto py-3 py-md-4 d-flex flex-column flex-md-row gap-2 gap-md-3 justify-content-center align-items-center">
										<img
											src={imgCalendar}
											alt="Fecha de inicio"
										/>
										<div>
											<span className="d-block fw-medium">
												14 de Abril
											</span>
										</div>
									</Col>
									<Col className="my-auto py-3 py-md-4 d-flex flex-column flex-md-row gap-2 gap-md-3 justify-content-center align-items-center">
										<img src={imgPin} alt="Ubicación" />
										<span className="text-capitalize fw-medium">
											- Campus UCC <br />- Videoconferencias
										</span>
									</Col>
									<Col className="my-auto py-3 py-md-4 d-flex flex-column flex-md-row gap-2 gap-md-3 justify-content-center align-items-center">
										<img
											src={imgReloj}
											alt="Duración del diplomado"
										/>
										<span className="text-capitalize fw-medium">
											7 meses
											<br />
											120 horas totales.
										</span>
									</Col>
									<Col className="my-auto py-3 py-md-4 d-flex flex-column flex-md-row gap-2 gap-md-3 justify-content-center align-items-center">
										<img src={imgDinero} alt="Precio" />
										<span className="text-capitalize fw-medium">
											Público en general <br />
											USD 782
										</span>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</section>
				<section id="info">
					<Container className=" pt-4 pb-5 pb-md-6">
						<Row className="mb-4">
							<Col className="text-center">
								<h3 className="title text-azul">
									Objetivos del curso
								</h3>
							</Col>
						</Row>
						<Row className="mb-4">
							<Col md={6}>
								{" "}
								<p className="p-style">
                                    Promover visión estratégica en el ámbito de los agronegocios y desarrollar capacidades técnicas para impulsar la profesionalización del gerenciamiento de las organizaciones del sector.
								</p>
								<p className="p-style">
                                    Adquirir habilidades que requiere el proceso de planificación estratégica de empresas agropecuarias y agroalimentarias.
								</p>
								<p className="p-style">
                                    Reconocer los factores clave del entorno de las empresas, las tendencias estructurales y driver del sector de los agronegocios.
								</p>
							</Col>
							<Col md={6}>
								<p className="p-style">
                                Desarrollar capacidades para analizar las cadenas de valor, las vinculaciones entre actores, la eficiencia de los mercados y los factores que impulsan la innovación en el ámbito de los agronegocios.
								</p>
								<p className="p-style">
                                    Reconocer la importancia, funcionamiento, normas y procedimientos del comercio internacional agroalimentario y detectar oportunidades de exportación.
								</p>
								<p className="p-style">
                                    Dominar las herramientas básicas del sistema de costos, presupuestos y gestión financiera de las empresas agroalimentarias.
								</p>
							</Col>
						</Row>
						<Row className="mb-4">
							<Col className="text-center">
								<h3 className="title text-azul">
									¿A quién está dirigido?
								</h3>
							</Col>
						</Row>
						<Row>
							<Col>
								{" "}
								<p className="p-style">
                                    Mandos medios y altos de empresas del medio, funcionarios de organizaciones público/privadas, profesionales independientes y estudiantes avanzados de disciplinas relacionadas al sector con vocación de perfilar su especialización en los Agronegocios.
								</p>
							</Col>
						</Row>
					</Container>
				</section>

				<section id="programa" className="bg-light-landing py-5 py-md-6">
					<Container>
						<Row>
							<Col className="text-center">
								<h3 class="title text-azul mb-3">Contenidos</h3>
							</Col>
						</Row>
						<Row>
							<Col>
								<Accordion>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="0"
										>
											Módulo 1
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="0">
											<Card.Body>
                                                <h6 className="fw-bold">Estrategia y plan de negocios</h6>
                                                Introducción al planeamiento estratégico. Dirección estratégica como proceso. Creación de la Estrategia. Fijación de objetivos. Análisis de la posición estratégica. Planificación por escenarios. Fuerzas competitivas del sector y la empresa. Factores de éxito. Comportamientos estratégicos. Evaluación de competencias y capacidades de la organización. Estrategias y ventajas competitivas. Implementación de la estrategia. Cultura organizacional. Liderazgo
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="1"
										>
											Módulo 2
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="1">
											<Card.Body>
                                                <h6 className="fw-bold">Entorno macro y competitividad</h6>
                                            Análisis del Macro entorno de la Firma. Dimensiones e Indicadores de competitividad (mundial, nacional y regional). Logística e infraestructura. Entorno macroeconómico nacional/regional/global. Encadenamientos, Clústeres y Redes. Perfil de inserción internacional agroindustrial y agroalimentaria regional. Formación de precios en el mercado agrícola internacional. Modelos de decisión.
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="2"
										>
											Módulo 3
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="2">
											<Card.Body>
                                                <h6 className="fw-bold">Análisis estratégico de cadenas AA</h6>
                                                Encadenamientos y vínculos. Relaciones Insumo Producto. Valor Agregado y Valor Económico Total. Barreras de Entrada y Rentas Económicas. Economías de Escala. Economías Externas. Integración vertical. Clúster y Redes. Bienes Públicos, acciones colectivas y acciones cooperativas. Información Asimétrica. Riesgo Moral. Costos de transacción. Contratos. Procesos de innovación en el agro.
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="3"
										>
											Módulo 4
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="3">
											<Card.Body>
                                                <h6 className="fw-bold">Comercio internacional agroalimentario</h6>
                                                Introducción al comercio exterior agroalimentario. Estrategias e inserción internacional. OMC, Normativa internacional. Incoterms Mercado mundial de commodities y specialities. Marketing internacional. Técnicas de negociación. Inteligencia comercial. Consorcios de exportación. Comercio electrónico.
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="4"
										>
											Módulo 5
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="4">
											<Card.Body>
												<h6 className="fw-bold">Contabilidad de costos, presupuestos y finanzas</h6>
                                                El proceso de gestión. Sistemas de contabilidad de costos. Métodos de costeo agropecuario. Normas de gestión. Sistema presupuestario. Presupuestos económicos y financieros. Contabilidad ambiental. Control y evaluación de gestión, indicadores, y cuadro de mando integral. Método de formulación de proyectos de inversión e Indicadores de evaluación. Alternativas de financiamiento. Activos financieros y derivados.
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</Col>
						</Row>
					</Container>
				</section>
			</main>
            <footer class="bg-azul">
                <Container className="py-5">
                    <Row>
                        <Col className="text-center">
                            <h2>Certificación Oficial de la Universidad Católica de Córdoba</h2>
                        </Col>
                    </Row>

                </Container>

    </footer>
		</>
	);
};

export default Agromanagement;
